.menu-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
}

.menu-btn__inner {
	flex-direction: column;
	margin-top: 7px;
	letter-spacing: .04em;
}

.menu-btn .txt {
	font-size: .70rem;
	margin-top: 7px;
	font-weight: bold;
}
